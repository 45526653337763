








import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'WatermarkOverlay',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup: (props, ctx) => {
    // from: https://stackoverflow.com/questions/29999515/get-final-size-of-background-image
    const getBackgroundSize = (elem: Element) => {
      const computedStyle = getComputedStyle(elem);
      const image = new Image();
      const src = computedStyle.backgroundImage.replace(
        /url\((['"])?(.*?)\1\)/gi,
        '$2'
      );

      if (src === 'none') {
        return {
          width: 0,
          height: 0
        };
      }

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      let cssSize: any = computedStyle.backgroundSize;
      const elemW = parseInt(computedStyle.width.replace('px', ''), 10);
      const elemH = parseInt(computedStyle.height.replace('px', ''), 10);
      const elemDim = [elemW, elemH];
      const computedDim = [];
      let ratio;
      image.src = src;
      ratio =
        image.width > image.height
          ? image.width / image.height
          : image.height / image.width;
      cssSize = cssSize.split(' ');
      computedDim[0] = cssSize[0];
      computedDim[1] = cssSize.length > 1 ? cssSize[1] : 'auto';
      if (cssSize[0] === 'cover') {
        if (elemDim[0] > elemDim[1]) {
          if (elemDim[0] / elemDim[1] >= ratio) {
            computedDim[0] = elemDim[0];
            computedDim[1] = 'auto';
          } else {
            computedDim[0] = 'auto';
            computedDim[1] = elemDim[1];
          }
        } else {
          computedDim[0] = 'auto';
          computedDim[1] = elemDim[1];
        }
      } else if (cssSize[0] === 'contain') {
        if (elemDim[0] < elemDim[1]) {
          computedDim[0] = elemDim[0];
          computedDim[1] = 'auto';
        } else {
          if (elemDim[0] / elemDim[1] >= ratio) {
            computedDim[0] = 'auto';
            computedDim[1] = elemDim[1];
          } else {
            computedDim[1] = 'auto';
            computedDim[0] = elemDim[0];
          }
        }
      } else {
        for (let i = cssSize.length; i--; ) {
          if (cssSize[i].indexOf('px') > -1) {
            computedDim[i] = cssSize[i].replace('px', '');
          } else if (cssSize[i].indexOf('%') > -1) {
            computedDim[i] = elemDim[i] * (cssSize[i].replace('%', '') / 100);
          }
        }
      }
      if (computedDim[0] === 'auto' && computedDim[1] === 'auto') {
        computedDim[0] = image.width;
        computedDim[1] = image.height;
      } else {
        ratio =
          computedDim[0] === 'auto'
            ? image.height / computedDim[1]
            : image.width / computedDim[0];
        computedDim[0] =
          computedDim[0] === 'auto' ? image.width / ratio : computedDim[0];
        computedDim[1] =
          computedDim[1] === 'auto' ? image.height / ratio : computedDim[1];
      }

      return {
        width: computedDim[0],
        height: computedDim[1]
      };
    };

    const width = ref('');
    onMounted(() => {
      const el = ctx.parent?.$el?.getElementsByClassName('v-image__image--contain')?.[0];

      width.value = el ? `${getBackgroundSize(el)?.width}px` : '100%';

      const observer = new MutationObserver((mutations, observer) => {
        // fired when a mutation occurs
        const el = ctx.parent?.$el?.getElementsByClassName('v-image__image--contain')?.[0];
        width.value = el ? `${getBackgroundSize(el)?.width}px` : '100%';
      });

      // define what element should be observed by the observer
      // and what types of mutations trigger the callback
      observer.observe(document, {
        subtree: true,
        attributes: true
      });
    });

    return {
      width
    };
  }
});
