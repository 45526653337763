













































































import { defineComponent, inject, onMounted, ref } from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';

import { format, parseISO } from 'date-fns';

import { FETCH_ASSET } from '@/store/operation.actions';

import VueRouter from 'vue-router';

import WatermarkOverlay from '@/components/WatermarkOverlay.vue';
import { GET_REPORTS } from '@/store/report.actions';
import { Report } from '@/models/report.model';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { isImg } from '@/helpers/image-entry-helpers';

export default defineComponent({
  name: 'ReportDetail',
  props: {
    id: {
      type: String
    }
  },
  components: {
    WatermarkOverlay,
    VuePdfEmbed
  },
  setup: props => {
    const getters = useGetters([GET_REPORTS]);
    const reports = getters[GET_REPORTS];

    const formatDate = (dateStr: string): string => {
      if (!dateStr || dateStr.length === 0) {
        return '';
      }

      const date = parseISO(dateStr);
      return date ? format(date, "dd.MM.yyyy 'um' HH:mm 'Uhr'") : '';
    };

    const id = props.id;
    const report = (reports.value as Report[]).find(op => op._id === id);

    const imgAssetUrls = ref([] as string[]);
    const pdfAssetUrls = ref([] as string[]);

    const actions = useActions([FETCH_ASSET]);
    const fetchAssetAction = actions[FETCH_ASSET];

    onMounted(async () => {
      if (!report?.bilder || report?.bilder.length === 0) {
        return;
      }

      // TODO: unifizieren mit Einsätzen
      report?.bilder.forEach(async (assetId: string) => {
        const asset = await fetchAssetAction(assetId);

        // asset path starts with /
        const host = window.location.hostname;
        let fetchUrl: string;
        if (host.startsWith('localhost')) {
          fetchUrl = 'http://localhost:8001/cockpit';
        } else {
          fetchUrl = `http://${host}/cockpit`;
        }

        const url = `${fetchUrl}/storage/uploads${asset.path}`;

        if (isImg(asset.path.toLowerCase())) {
          imgAssetUrls.value.push(url);
        } else {
          pdfAssetUrls.value.push(url);
        }
      });
    });

    const router = inject<VueRouter>('$router');
    const goBack = () => router?.go(-1);
    const magnified = ref(false);

    return {
      report,
      formatDate,
      imgAssetUrls,
      pdfAssetUrls,
      goBack,
      magnified
    };
  }
});
