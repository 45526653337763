import { ImageEntry } from '@/models/image-entry.model';
import { Asset, RemoveAsset } from '@/models/operation.model';
import { AssetType } from '@/types/asset.type';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import { VForm } from '@/types/form.type';
import { SelectEntry } from '@/types/select-entry.type';
import { Ref } from '@vue/composition-api';
import { format, getHours, getMinutes, parseISO, setHours, setMinutes } from 'date-fns';
import { Emitter } from 'mitt';

import Vue from 'vue';

export const clearAssets = async (
  removeAssets: { _id: string }[],
  confirmDialog: Ref<boolean>,
  imageEntry: ImageEntry | undefined,
  removeImageAction: Function | ((assets: RemoveAsset[]) => Promise<RemoveAsset[]>),
  createSaveEntryAction: Function | ((imageEntry: ImageEntry) => Promise<ImageEntry>),
  $emitter: Emitter
) => {
  if (!imageEntry || !removeAssets || removeAssets.length === 0) {
    return;
  }

  const removedAssets = await removeImageAction(removeAssets);

  if (
    removedAssets?.length > 0 &&
    removedAssets?.length <= removeAssets?.length
  ) {
    const updateRep = {
      ...imageEntry,
      bilder: imageEntry.bilder?.filter(
        b => !removedAssets.some((a: { _id: string }) => a._id === b)
      )
    };

    const res = await createSaveEntryAction(updateRep);
    if (res) {
      confirmDialog.value = false;
    }
  } else {
    const msg: Message = {
      type: MessageType.Error,
      text: 'Bilder/PDFs konnten nicht gelöscht werden'
    };

    $emitter?.emit(MessageEvent.Global, msg);
  }
};

export const clearNewAssets = async (
  assetUrls: Ref<string[]>,
  assets: Ref<File[]>,
  confirmDialog: Ref<boolean>
) => {
  assetUrls.value.forEach(img => URL.revokeObjectURL(img));
  assetUrls.value = [];

  assets.value = [];
  confirmDialog.value = false;
};

export const isImg = (src: string) =>
  /\.(gif|jpg|jpeg|tiff|png)$/i.test(src.toLowerCase());

export const createAssetUrl = ( // TODO: remove
  asset: File,
  assetToType: Ref<{ [key: string]: AssetType }>
) => {
  const url = URL.createObjectURL(asset);
  if (isImg(asset.name.toLowerCase())) {
    assetToType.value[url] = 'img';
  } else {
    assetToType.value[url] = 'pdf';
  }
  return url;
};

export const fetchAsset = async (
  assetId: string,
  fetchAssetAction: Function | ((assetId: string) => Promise<Asset>)
) => {
  const asset = await fetchAssetAction(assetId);

  const host = window.location.hostname;
  let fetchUrl: string;
  if (host.startsWith('localhost')) {
    fetchUrl = 'http://localhost:8001/cockpit';
  } else {
    fetchUrl = `http://${host}/cockpit`;
  }

  const url = `${fetchUrl}/storage/uploads${asset.path}`;
  return { url, asset };
};

export const fetchAssetsForImageEntry = async (
  fetchAssetAction: Function | ((assetId: string) => Promise<Asset>),
  assetId: string,
  imgAssetUrls: Ref<string[]>,
  imgAssets: Ref<File[]>,
  pdfAssetUrls: Ref<string[]>,
  pdfAssets: Ref<File[]>
) => { // TODO: remove
  const { url, asset } = await fetchAsset(assetId, fetchAssetAction);

  if (isImg(asset.path.toLowerCase())) {
    imgAssetUrls.value.push(url);
    imgAssets.value.push(new File([], asset.title));
  } else {
    pdfAssetUrls.value.push(url);
    pdfAssets.value.push(new File([], asset.title));
  }

  return { url, asset };
};

export const fetchPreviewAssetForImageEntry = async (
  fetchAssetAction: Function | ((assetId: string) => Promise<Asset>),
  assetId: string,
  assetUrl: Ref<string>,
  _asset: Ref<File>,
  assetToType: Ref<{ [key: string]: AssetType }>
) => {
  const { url, asset } = await fetchAsset(assetId, fetchAssetAction);
  assetUrl.value = url;

  if (isImg(asset.path.toLowerCase())) {
    _asset.value = new File([], asset.title);
    assetToType.value[url] = 'img';
  } else {
    _asset.value = new File([], asset.title);
    assetToType.value[url] = 'pdf';
  }
};

export const clearPreviewAsset = async (
  isEditing: Ref<boolean>,
  imageUrl: Ref<string>,
  image: Ref<File>,
  confirmDialog: Ref<boolean>,
  isEditingFirst: Ref<boolean>,
  useInputImages: Ref<boolean>,
  imageEntry: ImageEntry | undefined,
  removeImageAction: Function | ((assets: RemoveAsset[]) => Promise<RemoveAsset[]>),
  createSaveEntryAction: Function | ((imageEntry: ImageEntry) => Promise<ImageEntry>),
  $emitter: Emitter
) => {
  if (!isEditing.value || !isEditingFirst.value) {
    URL.revokeObjectURL(imageUrl.value);

    image.value = (null as unknown) as File;
    imageUrl.value = '';
    confirmDialog.value = false;
  } else if (isEditing.value && isEditingFirst.value) {
    if (!imageEntry || !imageEntry.vorschaubild) {
      return;
    }

    isEditingFirst.value = false;
    useInputImages.value = false;

    const removeAsset = { _id: imageEntry.vorschaubild };
    const removedAssets = await removeImageAction([removeAsset]);

    if (removedAssets?.length > 0 && removedAssets?.length <= 1) {
      const updateRep = {
        ...imageEntry,
        vorschaubild: ''
      };

      const res = await createSaveEntryAction(updateRep);
      if (res) {
        confirmDialog.value = false;
        image.value = (null as unknown) as File;
        imageUrl.value = '';
      }
    } else {
      const msg: Message = {
        type: MessageType.Error,
        text: 'Bild/PDF konnte nicht gelöscht werden'
      };

      $emitter?.emit(MessageEvent.Global, msg);
    }
  }
};

export const deleteImageEntry = async (
  entry: ImageEntry,
  removeImageAction: Function | ((assets: RemoveAsset[]) => Promise<RemoveAsset[]>),
  deleteAction: Function | ((id: string) => Promise<void>),
  confirmDialog: Ref<boolean>
) => {
  if (!entry) {
    return;
  }

  if (entry.bilder && entry.bilder.length > 0) {
    const removeAssets = entry.bilder.map(img => ({ _id: img }));
    if ((entry.vorschaubild as string)?.length > 0) {
      removeAssets.push({ _id: entry.vorschaubild as string });
    }

    const removedAssets = await removeImageAction(removeAssets);
    if (
      removedAssets?.length > 0 &&
      removedAssets?.length <= removeAssets?.length
    ) {
      await deleteAction(entry._id as string);
    }
  } else {
    await deleteAction(entry._id as string);
  }

  confirmDialog.value = false;
};

export const watchImageEntries = (
  entries: ImageEntry[],
  reportThumbnailUrls: Ref<{
    [key: string]: { type: 'img' | 'pdf' | 'placeholder'; url?: string };
  }>,
  fetchAssetAction: Function | ((assetId: string) => Promise<Asset>),
  fetchImageAction: Function | ((assetId: string) => Promise<string>)
) => {
  entries.forEach(async entry => {
    if (!entry || !entry?.vorschaubild) {
      Vue.set(reportThumbnailUrls.value, entry?._id as string, {
        type: 'placeholder'
      });
      return;
    }

    const assetId = entry.vorschaubild;
    const asset = await fetchAssetAction(assetId);

    if (isImg(asset.path.toLowerCase())) {
      const imageUrl = await fetchImageAction(assetId);
      Vue.set(reportThumbnailUrls.value, entry._id as string, {
        type: 'img',
        url: imageUrl as string
      });
    } else {
      // asset path starts with /
      const host = window.location.hostname;
      let fetchUrl: string;
      if (host.startsWith('localhost')) {
        fetchUrl = 'http://localhost:8001/cockpit';
      } else {
        fetchUrl = `http://${host}/cockpit`;
      }

      const url = `${fetchUrl}/storage/uploads${asset.path}`;
      Vue.set(reportThumbnailUrls.value, entry._id as string, {
        type: 'pdf',
        url
      });
    }
  });
};

export const calcPageCount = (entries: ImageEntry[], perPage: number) =>
  Math.ceil(entries.length / perPage);

export const url = (assets: Ref<File[]>, assetUrls: Ref<string[]>) => {
  if (!assets.value) {
    return;
  }

  // alte Objekte aufräumen bevor neue angelegt werden
  assetUrls.value.forEach(asset => URL.revokeObjectURL(asset));
  assetUrls.value = assets.value.map(asset => URL.createObjectURL(asset));
};

export const singleUrl = (
  asset: Ref<File>,
  assetUrl: Ref<string>,
  previewAssetToType: Ref<{ [key: string]: AssetType }>
) => {
  if (!asset.value) {
    return;
  }

  // alte Objekte aufräumen bevor neue angelegt werden
  URL.revokeObjectURL(assetUrl.value);
  assetUrl.value = URL.createObjectURL(asset.value);

  if (isImg(asset.value.name.toLowerCase())) {
    previewAssetToType.value[assetUrl.value] = 'img';
  } else {
    previewAssetToType.value[assetUrl.value] = 'pdf';
  }
};

export const clearAssetsUrls = (
  imgAssetUrls: Ref<string[]>,
  pdfAssetUrls: Ref<string[]>,
  previewAssetUrl: Ref<string>,
  imgAssets: Ref<File[]>,
  pdfAssets: Ref<File[]>,
  previewAsset: Ref<File>,
  previewAssetToType: Ref<{ [key: string]: AssetType }>
) => {
  imgAssetUrls.value.forEach(asset => URL.revokeObjectURL(asset));
  imgAssetUrls.value = [];
  imgAssets.value = [];

  pdfAssetUrls.value.forEach(asset => URL.revokeObjectURL(asset));
  pdfAssetUrls.value = [];
  pdfAssets.value = [];

  URL.revokeObjectURL(previewAssetUrl.value);
  previewAssetUrl.value = '';
  previewAsset.value = (null as unknown) as File;
  previewAssetToType.value = {};
};

export const sendEntryForm = async <
  T extends ImageEntry,
  TInput extends ImageEntry
>(
  form: Ref<VForm>,
  imgAssets: Ref<File[]>,
  pdfAssets: Ref<File[]>,
  uploadImageAction: Function | ((assets: File[]) => Promise<string[]>),
  useInputPreviewAsset: Ref<boolean>,
  previewAsset: Ref<File>,
  time: Ref<string>,
  entry: T,
  isEditing: Ref<boolean>,
  date: Ref<string>,
  createSaveAction: Function | ((entry: TInput) => Promise<T>),
  fillEntryData: Function | ((entry: TInput) => void),
  preprocess?: Function | (() => Promise<void>)
) => {
  if (!entry && isEditing.value) {
    return;
  }

  const vform = form?.value as VForm;
  if (!vform?.validate()) {
    return;
  }

  // Nur uploaden, wenn nicht ursprüngliche Eingabe-Assets verwendet werden sollen
  const newAssets = [...(imgAssets?.value ?? []), ...(pdfAssets?.value ?? [])];
  const uploadAssetIds =
    newAssets?.length > 0 ? await uploadImageAction(newAssets) : [];

  const uploadPreviewAssetId =
    !useInputPreviewAsset.value && previewAsset.value
      ? await uploadImageAction([previewAsset.value])
      : null;

  if (preprocess) {
    await preprocess();
  }

  const timeSplit = time.value?.split(':');
  let timestamp = parseISO(date.value);
  if (timeSplit) {
    timestamp = setHours(timestamp, +timeSplit[0]);
    timestamp = setMinutes(timestamp, +timeSplit[1]);
  }

  const imgCount = entry?.bilder?.length ?? 0;
  if (imgCount > 0) {
    uploadAssetIds.push(...(entry?.bilder ?? []));
  }

  const newEntry = {
    zeitstempel: timestamp.toISOString(),
    bilder: uploadAssetIds,
    vorschaubild: useInputPreviewAsset.value
      ? entry?.vorschaubild
      : uploadPreviewAssetId?.[0]
  } as TInput;

  fillEntryData(newEntry);

  if (isEditing.value) {
    newEntry._id = entry?._id;
  }

  return await createSaveAction(newEntry);
};

export const updateEntryEditAssets = async <T extends ImageEntry>(
  entry: T,
  editAssets: Ref<SelectEntry[]>,
  fetchAssetAction: Function | ((assetId: string) => Promise<Asset>),
  imgAssetUrls?: Ref<string[]>,
  pdfAssetUrls?: Ref<string[]>
) => {
  editAssets.value = [];

  if (imgAssetUrls) {
    imgAssetUrls.value = [];
  }
  if (pdfAssetUrls) {
    pdfAssetUrls.value = [];
  }

  if (!entry) {
    return;
  }

  entry.bilder?.forEach(async (assetId: string) => {
    const { url, asset } = await fetchAsset(assetId, fetchAssetAction);

    editAssets.value.push({
      text: asset.title,
      value: asset._id
    });

    if (!imgAssetUrls || !pdfAssetUrls) {
      return;
    }

    if (isImg(asset.path.toLowerCase())) {
      imgAssetUrls.value.push(url);
    } else {
      pdfAssetUrls.value.push(url);
    }
  });
};

export const formatDate = (dateStr: string): string => {
  if (!dateStr || dateStr.length === 0) {
    return '';
  }

  const date = parseISO(dateStr);
  return date ? format(date, "dd.MM.yyyy', ' HH:mm 'Uhr'") : '';
};

export const formatDay = (dateStr: string): string => {
  if (!dateStr || dateStr.length === 0) {
    return '';
  }

  const date = parseISO(dateStr);
  return date ? format(date, 'dd.MM.yyyy') : '';
};

export const formatTime = (dateStr: string): string => {
  if (!dateStr || dateStr.length === 0) {
    return '';
  }

  const date = parseISO(dateStr);
  return date ? format(date, "HH:mm 'Uhr'") : '';
};

export const getTimeString = (timestampInput: Date | null) => {
  const now = new Date();
  return timestampInput
    ? `${getHours(timestampInput)}:${getMinutes(timestampInput) < 10 ? 0 : ''}${getMinutes(timestampInput)}`
    : `${getHours(now)}:${getMinutes(now) < 10 ? 0 : ''}${getMinutes(now)}`;
};

export const getDateString = (timestampInput: Date | null) => {
  const now = new Date();
  return timestampInput
    ? format(timestampInput as Date, 'yyyy-MM-dd') // timestampInput.toISOString().substr(0, 10)
    : now.toISOString().substr(0, 10);
};

export const imageEntryWatcher = (
  newEntries: ImageEntry[],
  editAssets: Ref<SelectEntry[]>,
  id: string | undefined,
  fetchAssetAction: Function | ((assetId: string) => Promise<Asset>),
  imgAssetUrls?: Ref<string[]>,
  pdfAssetUrls?: Ref<string[]>
) => {
  if (!id) {
    return;
  }

  const currEntry = (newEntries as ImageEntry[])?.find(
    entry => entry._id === id
  );

  if (!currEntry) {
    return;
  }

  updateEntryEditAssets(
    currEntry,
    editAssets,
    fetchAssetAction,
    imgAssetUrls,
    pdfAssetUrls
  );
};
